<template>
  <div id="no-order-item-component">
    <slide-heading :html-heading="heading" />

    <div v-if="description">
      <div class="description">
        <div v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'NoOrderItem',
  components: {
    SlideHeading: () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  computed: {
    description() {
      return DOMPurify.sanitize('')
    },
    heading() {
      return `It doesn't look like you have an active filing yet.`
    },
  },
}
</script>

<style scoped lang="scss"></style>
